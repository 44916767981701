.card {
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 20px;
    margin: 10px 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    background-color: #fff;
    transition: transform 0.3s;
    width: 28%;
}

.card:hover {
    /* transform: translateY(-5px); */
}

.card-img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    border-radius: 5px;
}

.card-title {
    margin-top: 10px;
}

.card-description {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
}
