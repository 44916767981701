.ProjectList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.ProjectList .project,
.ProjectList .ProjectComponent {
    background-size: cover;
    width: 100%;
    height: 300px;
    color: tomato;
    margin-top: 50px;
    border: 3px solid #1d1d1d;
    /*  */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.ProjectList .title_ {
    padding-left: 20px;

    height: 15%;
    background-color: #eee;
    color: #1d1d1d;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    font-family: "Nexa Slab Black";
    font-size: 15pt;

    margin-top: 0px;
    padding-top: 0px;
}
.ProjectList .link {
    padding-left: 20px;

    height: 15%;
    background-color: #0056ff;
    color: #eee;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;

    font-family: "Nexa Slab Thin";
    font-size: 14pt;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 600px) {
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .ProjectList .project,
    .ProjectList .ProjectComponent {
        background-size: cover;
        width: 47%;
    }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .ProjectList .project,
    .ProjectList .ProjectComponent {
        background-size: cover;
        width: 44%;
    }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .ProjectList .project,
    .ProjectList .ProjectComponent {
        background-size: cover;
        width: 30%;
    }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
