@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@300&display=swap");
.contact-us-heading,
.contact-us-address {
    font-family: "Noto Sans Arabic", sans-serif;
    text-align: center;
    font-size: 44px;
    margin-top: 80px;
    margin-bottom: 0;
}

.contact-us-address {
    font-size: 24px;
    text-align: right;
    padding-bottom: 10px;
}
.location iframe {
    width: 100%;
    height: 80vh;
}
.social {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 30px;
}

.social .social-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.social a {
    font-size: 50px;
    padding: 5px 20px;
}

a.youtube {
    color: #eb3223 !important;
}

a.facebook {
    color: #4968ad !important;
}

a.twitter {
    color: #49a1eb !important;
}

a.instagram {
    color: black !important;
}
a.whatsapp {
    color: #43d854 !important;
}
