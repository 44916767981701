.Brand {
    font-family: "Noto Sans";
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.Brand img {
    width: 100%;
    border-radius: 50%;
    margin-top: 20px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 600px) {
    .Brand {
        width: 50%;
    }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .Brand {
        width: 33%;
    }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .Brand {
        width: 25%;
    }
}
