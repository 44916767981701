a {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Use parent element's text color (default) */
    outline: none; /* Remove focus outline */
}

a:hover {
    color: inherit; /* Change text color on hover to red */
}

a:visited {
    color: inherit; /* Change color of visited links to purple */
}

.ServiceSection-container {
    width: 100%;
}
.ServiceSection-container .content {
    width: 90%;
    margin: auto;
    padding: 20px 0px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ServiceSection-container .content .text-container {
    /* padding: 10px 50px; */
    width: 50%;
}
.ServiceSection-container .content .text-container h2 {
    font-family: "Trap Black";
    font-size: 4em;
}
.ServiceSection-container .content .text-container p {
    font-family: "Trap SemiBold";
    height: 55%;
    font-size: 1.7em;
    line-height: 1.3;
}
.ServiceSection-container .content .text-container .LinkTo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.ServiceSection-container .content .text-container a {
    display: block;
    height: 45px;
    line-height: 45px;
    text-align: center;
    min-height: 45px;
    width: 320px;
    border: none;
    font-family: "Trap Medium";
    font-size: 1.4em;
    border-radius: 25px;
    color: white;
}

.ServiceSection-container .content .img-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ServiceSection-container .content .img-container img {
    width: 90%;
}
