@font-face {
    font-family: "Nexa Slab Regular Italic";
    src: local("Nexa Slab Regular Italic"),
        url(./fonts/Fontfabric\ -\ NexaSlabRegularItalic.otf) format("opentype");
}
@font-face {
    font-family: "Nexa Slab xBold Italic";
    src: local("Nexa Slab xBold Italic"),
        url(./fonts/Fontfabric\ -\ NexaSlabxBoldItalic.otf) format("opentype");
}

@font-face {
    font-family: "Nexa Slab Thin Italic";
    src: local("Nexa Slab Thin Italic"),
        url(./fonts/Fontfabric\ -\ NexaSlabThinItalic.otf) format("opentype");
}
@font-face {
    font-family: "Nexa Slab Thin";
    src: local("Nexa Slab Thin"),
        url(./fonts/Fontfabric\ -\ NexaSlabThin.otf) format("opentype");
}
@font-face {
    font-family: "Nexa Bold Regular";
    src: local("Nexa Bold Regular"),
        url(./fonts/Nexa\ Bold.otf) format("opentype");
}
@font-face {
    font-family: "Nexa Slab Book Italic Italic";
    src: local("Nexa Slab Book Italic Italic"),
        url(./fonts/Fontfabric\ -\ NexaSlabBookItalic.otf) format("opentype");
}

/* Apply these styles to the main container */
.pageLayout {
    display: flex;
    flex-direction: column;
    flex: 1;
}

body {
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: sans-serif, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    /* -webkit-font-smoothing: antialiased; */
    /* -moz-osx-font-smoothing: grayscale; */
}

.container {
    width: 80%;
    margin: auto;
}

@media (max-width: 576px) {
    html {
        font-size: 13px;
    }
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 768px) {
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 992px) {
}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1200px) {
}
