.Team {
  /* min-height: 100vh; */
  background: #eee;
  color: #1d1d1d;
  padding-top: 30px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.Team .teamMember {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 30%;
  margin-top: 30px;
}

.Team .teamMember .info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}
.Team .teamMember .info .name,
.Team .teamMember .info .job {
  /* line-height: 0px; */
  margin: 0px;
  font-family: "Nexa Slab xBold Italic";
}
.Team .teamMember .info .name {
  font-size: 18pt;
  margin: 0;
  text-align: center;
}
.Team .teamMember .info .job {
  font-size: 12pt;
  margin-top: 3px;
}
.Team .teamMember img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
