.AndMore {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 30px 0px;
    font-size: 18pt;
    font-family: "Nexa Slab xBold Italic";
}
.AndMore hr {
    width: 100%;
    border: 1px solid #1d1d1d;
    margin-top: 50px;
}
.AndMore span {
    margin-top: -30px;
    background-color: #eee;
    padding: 0px 35px;
}
