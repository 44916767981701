.head-section-dark-mode {
    color: #eee;
    background: #1d1d1d;
}
.head-section-container {
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Head {
    height: 65vh;
}
.Head .content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Head .content .Word1 {
    font-family: "Nexa Slab Regular Italic", sans-serif;
    font-size: 3.5rem;
    margin: 0px;
}
.Head .content .Word2 {
    font-family: "Nexa Slab xBold Italic", sans-serif;
    font-size: 5rem;
}
.Head .content span {
    display: block;
    font-style: italic;
    line-height: 90%;
}

.Head .content img {
    max-width: 60%;
    overflow: hidden;
}

.blue-square {
    margin-top: -11rem;
    width: 8rem;
    height: 11rem;
    background-color: #0056ff;
}

@media (max-width: 576px) {
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 768px) {
    .blue-square {
        display: none;
    }
    .Head {
        height: 70vh;
    }
    .Head .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 7vh;
        height: 65%;
    }

    .Head .content .Word1 {
        font-family: "Nexa Slab Regular Italic", sans-serif;
        font-size: 4rem;
        margin: 0px;
        margin-right: 15px;
    }
    .Head .content .Word2 {
        font-family: "Nexa Slab Regular Italic", sans-serif;
        font-size: 4rem;
    }
    .Head .content span {
        display: inline;
        font-style: italic;
        line-height: 90%;
    }

    .Head .content img {
        width: 80%;
    }
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 992px) {
}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1200px) and (min-width: 768px) {
    .Head {
        height: 60vh;
    }
}
