* {
    font-family: "Nexa Slab Regular";
}
.view-container {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.titles-container {
    width: 80%;
    margin: auto;
}

.titles-container h1 {
    margin-bottom: 0px;
}
.titles-container h2 {
    margin-top: 0px;
}

.spinner-container {
    display: flex; /* Use flexbox to center the spinner */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    height: 100vh; /* Take full viewport height */
    background-color: rgba(
        255,
        255,
        255,
        0.8
    ); /* Add a semi-transparent background */
    position: fixed; /* Position the spinner fixed on the screen */
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999; /* Ensure the spinner is on top of other content */
}
