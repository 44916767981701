.overlay-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Transparent black background */
    z-index: 1000;
    /* Adjust z-index as needed */
    display: flex;
    flex-direction: column;
    /* Stack the button and content vertically */
    justify-content: flex-start;
    /* Align items to the top */
    align-items: flex-end;
    /* Align items to the right */
    overflow-y: scroll;
    /* Add vertical scroll if content overflows */
}

.close-button {
    position: absolute;
    /* Position the button absolutely */
    top: 30px;
    /* Distance from the top */
    right: 30px;
    /* Distance from the right */
    padding: 5px 10px;
    /* Add some padding for better appearance */
    background-color: #333;
    /* Button background color */
    color: white;
    /* Button text color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.close-img {
    position: fixed;
    /* Position the button absolutely */
    top: 3.1rem;
    /* Distance from the top */
    right: 3.1rem;
    /* Distance from the right */
    padding: 5px 10px;
    /* Add some padding for better appearance */
    color: white;
    /* Button text color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 25px;
    height: 25px;
}

.overlay-content {
    background-color: white;
    width: 80%;
    margin: auto;
}

.title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
    font-family: "Nexa Slab xBold", sans-serif;
}

.description {
    margin-bottom: 20px;
}

.image-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 100px;
}

.image-list img {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
}