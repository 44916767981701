@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@300&display=swap");

.AboutUsPage {
    min-height: 100vh;
}

.about-content {
    padding: 100px 10px;
    direction: rtl;
    font-family: "Noto Sans Arabic", sans-serif;
    max-width: 80%;
    margin: auto;
    font-size: 32px;
    text-align: center;
}
.img-container {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.img-style {
    margin: auto;
    padding: 30px 0px;
    width: inherit;
    max-width: 700px;
}

.buttons-section {
    padding: 20px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.buttons-section .container {
    width: auto;
    padding-top: 30;
    padding-bottom: 60px;
}
.buttons-section button {
    /* Reset default styles */
    border: none;
    outline: none;
    padding: 5px 20px;
    cursor: pointer;

    /* Material design-like styles */
    background-color: #2196f3; /* Nice blue color */
    color: #fff;
    border-radius: 20px; /* Rounded corners */
    transition: background-color 0.3s ease-in-out;

    margin: 0px 10px;
    font-size: 20px;
    font-family: "Noto Sans Arabic", sans-serif;
    font-weight: bold;
}

.buttons-section button:hover {
    background-color: #1565c0; /* Darker blue color on hover */
}
