.service {
    width: 100%;
    border: 3px solid #1d1d1d;
    margin: 30px 0px;
    min-height: 290px;
    background-color: #1d1d1d;
    color: #eee;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.service .title {
    width: 100%;
    height: 120px;
    color: #1d1d1d;
    background-color: #eee;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0px;
}
.service .title span {
    display: block;
    width: 80%;
    font-family: "Nexa Bold Regular";
    font-size: 28pt;
}
.service .body {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}
.service .body .container .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.service .body .link {
    display: block;
    background-color: rgb(0, 86, 255);
    color: #eee;
    width: 100%;
    text-align: center;
    border-radius: 20px;
    text-decoration: none;
    font-size: 19pt;
    font-family: "Nexa Slab Book Italic Italic";
    height: 40px;
    padding-top: 3px;
}
.single-service-container {
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .service {
        width: 75%;
        margin: 30px 0px;
        min-height: 290px;
    }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .service {
        width: 70%;
        margin: 30px 0px;
        min-height: 290px;
    }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .service {
        width: 45%;
        margin: 30px 0px;
        min-height: 290px;
    }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .service {
        width: 40%;
        margin: 30px 0px;
        min-height: 290px;
        justify-content: space-around;
    }
}
