.dark {
    color: #eee;
    background: #1d1d1d;
}
.Footer {
    min-height: 40px;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90%;
}
.icons {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.ico {
    margin: 3px;
    width: 2rem;
    transition: all 200ms;
}
.brand {
    width: auto;
}
.ico:hover {
    color: rgb(0, 86, 255);
}
.hr {
    width: -webkit-fill-available;
}

hr {
    border: solid 1px gray;
}
